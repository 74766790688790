<script setup>
import {Log} from '@devanjs/log';
import {escapeQuotes} from '@teemill/utilities';
import {useHead} from '@vueuse/head';
import {inject, computed} from 'vue';
import {useStore} from 'vuex';
import {useRoute} from 'vue-router';
import {breadcrumbs} from '@teemill/common/plugins';

import PageRenderer from './PageRenderer.vue';

const store = useStore();
const route = useRoute();

const props = defineProps({
  pageId: {
    required: false,
    type: Number,
  },
});

const app = inject('app');

const page = computed(() => {
  const page = app.page(props.pageId);

  if (page === undefined) {
    return;
  }

  Log.tag('pages-v')
    .orange('Container')
    .text('Loading Page')
    .lightBlue(page.id, page.uuid)
    .lightGreen(page.blocks.length)
    .info();

  return page;
});

const faqMeta = computed(() => {
  if (!page.value?.blocks) {
    return '';
  }

  const faqBlocks = page.value.blocks.filter(
    block =>
      block.type.name === 'expandable' &&
      block.property('questionMeta', 'string') &&
      block.property('answerMeta', 'string')
  );

  const faqMeta = faqBlocks.map(
    block => `{
      "@type": "Question",
      "name": "${escapeQuotes(block.property('questionMeta', 'string'))}",
      "acceptedAnswer": {
        "@type": "Answer",
        "text": "${escapeQuotes(block.property('answerMeta', 'string'))}"
      }
    }`
  );

  return faqMeta.length ? `[${faqMeta.join(',')}]` : '';
});

const ogImageMeta = computed(() => {
  return (
    page.value.property('ogImage') ||
    store.state.subdomain?.company?.logo ||
    store.state.logo?.url ||
    ''
  );
});

performance.mark('page-container-created');

useHead(
  computed(() => {
    if (!page.value) {
      return {};
    }

    const host = `${location.protocol}//${location.hostname}`;

    const meta = {
      title: page.value.property('title'),
      script: [],
      meta: [
        {
          vmid: 'og:title',
          property: 'og:title',
          content: page.value.property('title'),
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: page.value.property('description'),
        },
        {vmid: 'og:image', property: 'og:image', content: ogImageMeta.value},
        {vmid: 'og:type', property: 'og:type', content: 'website'},

        {
          vmid: 'twitter:title',
          property: 'twitter:title',
          content: page.value.property('title'),
        },
        {vmid: 'twitter:card', property: 'twitter:card', content: 'photo'},
        {
          vmid: 'twitter:image',
          property: 'twitter:image',
          content: ogImageMeta.value,
        },
        {
          vmid: 'description',
          name: 'description',
          content: page.value.property('description'),
        },
      ],
      link: [
        {
          vmid: 'canonical',
          rel: 'canonical',
          href: `${host}${route.path}`,
        },
      ],
    };

    if (faqMeta.value) {
      meta.script.push({
        type: 'application/ld+json',
        children: `
        {
          "@context": "http://schema.org/",
          "@type": "FAQPage",
          "mainEntity": ${faqMeta.value}
        }
      `,
      });
    }

    if (route.name !== '404') {
      meta.script.push({
        type: 'application/ld+json',
        children: `
        {
          "@context": "http://schema.org/",
          "@type": "BreadcrumbList",
          "itemListElement": [
            ${breadcrumbs.breadcrumbs.getLdJSON()}
          ]
        }
      `,
      });
    }

    if (
      !page.value.sitemap ||
      ['logged-in', 'dashboard-access'].includes(
        page.value.property('loginState')
      )
    ) {
      meta.meta.push({name: 'robots', content: 'noindex'});
    }

    return meta;
  })
);
</script>

<template>
  <page-renderer v-if="page" :page="page" />
</template>
