import {clamp, omitBy, isUndefined} from 'lodash';

import {ImageApiProvider} from './providers/imageApiProvider';
import {decorate} from '@teemill/utilities';
import {generateSrcSetDimensions} from './utils/generateSrcSetDimensions';
import type {SrcSetDimensions} from './types/srcSetDimensions';

export type ImageType = 'png' | 'jpg' | 'jpeg' | 'webp' | 'gif' | 'pdf';
export type ImageFitType = 'contain' | 'cover';
export type ImagePosition =
  | 'center'
  | 'top'
  | 'right'
  | 'bottom'
  | 'left'
  | 'left-top'
  | 'right-top'
  | 'right-bottom'
  | 'left-bottom';

export interface ImageOptions {
  origin?: string;
  file: string;
  type: ImageType;
  width: number;
  height: number;
  zoom: number;
  focal: {x: number; y: number};
  private: boolean;
  padding: {top?: number; right?: number; bottom?: number; left?: number};
  position?: ImagePosition;
  fit?: ImageFitType;
  mirror: {x?: boolean; y?: boolean};
  rotate?: number;
  reflect?: number;
  extend?: number;
  dpi?: number;
  background?: string;
}

export interface ImageProvider {
  supports(url: string): boolean;
  fromUrl(url: string): ImageOptions;
  toUrl(domain: ImageOptions): string;
}

export interface ImageUrlString extends String {
  png(): ImageUrlString;
  jpg(): ImageUrlString;
  webp(): ImageUrlString;
  pdf(): ImageUrlString;
}

export class ImageUrl {
  protected static defaultProvider: ImageProvider = new ImageApiProvider();

  public readonly options: ImageOptions;

  protected provider: ImageProvider;

  constructor(
    url: string,
    options: Partial<ImageOptions>,
    provider?: ImageProvider
  );
  constructor(
    url: ImageUrlString,
    options: Partial<ImageOptions>,
    provider?: ImageProvider
  );
  constructor(
    url: any,
    options: Partial<ImageOptions>,
    provider?: ImageProvider
  ) {
    this.provider = provider || ImageUrl.defaultProvider;

    this.options = this.formatOptions({
      ...this.provider.fromUrl(url as string),
      ...omitBy(options, isUndefined),
    });
  }

  protected formatOptions(options: ImageOptions): ImageOptions {
    options.width = Math.max(options.width, 0) || 0;
    options.height = Math.max(options.height, 0) || 0;
    options.zoom = Math.max(options.zoom, 0);

    options.focal.x = clamp(options.focal.x, 0, 1);
    options.focal.y = clamp(options.focal.y, 0, 1);

    return options;
  }

  public static supports(url: string): boolean;
  public static supports(url: string, provider: ImageProvider): boolean;
  public static supports(url: string, provider?: ImageProvider): boolean {
    return (provider || ImageUrl.defaultProvider).supports(url);
  }

  public type(type: ImageType): ImageUrl {
    this.options.type = type;

    return this;
  }

  public size(width: number, height: number): ImageUrl {
    this.options.width = width;
    this.options.height = height;

    return this;
  }

  public focal(x: number, y: number): ImageUrl {
    this.options.focal = {x, y};

    return this;
  }

  public zoom(zoom: number): ImageUrl {
    this.options.zoom = zoom;

    return this;
  }

  public png(): ImageUrl {
    return this.type('png');
  }

  public jpg(): ImageUrl {
    return this.type('jpg');
  }

  public webp(): ImageUrl {
    return this.type('webp');
  }

  public padding(top: number, right: number, bottom: number, left: number): ImageUrl {
    this.options.padding = {top, right, bottom, left};

    return this;
  }

  public mirror(x: boolean, y: boolean): ImageUrl {
    this.options.mirror = {x, y};

    return this;
  }

  public reflect(reflect: number): ImageUrl {
    this.options.reflect = reflect;

    return this;
  }

  public extend(extend: number): ImageUrl {
    this.options.extend = extend;

    return this;
  }

  public rotate(rotate: number): ImageUrl {
    this.options.rotate = rotate;

    return this;
  }

  public fit(fit: ImageFitType): ImageUrl {
    this.options.fit = fit;

    return this;
  }

  public position(position: ImagePosition): ImageUrl {
    this.options.position = position;

    return this;
  }

  public dpi(dpi: number): ImageUrl {
    this.options.dpi = dpi;

    return this;
  }

  public background(background: string): ImageUrl {
    this.options.background = background;

    return this;
  }

  public srcSet(sizes?: SrcSetDimensions): string {
    if (!sizes) {
      sizes = generateSrcSetDimensions(this.options.width, this.options.height);
    }

    const src = this.toString();

    return sizes
      .map(({width, height}) => {
        const url = new ImageUrl(src, {width, height}).toString();

        return `${url} ${width}w`;
      })
      .join(', ');
  }

  public toString(): ImageUrlString {
    const string = this.provider.toUrl(
      this.formatOptions(this.options)
    ) as unknown as ImageUrlString;

    decorate(string, 'png', () => this.png().toString());
    decorate(string, 'jpg', () => this.jpg().toString());
    decorate(string, 'webp', () => this.webp().toString());
    decorate(string, 'srcSet', (sizes?: SrcSetDimensions) =>
      this.srcSet(sizes).toString()
    );

    return string;
  }
}
