<script setup lang="ts">
import {computed} from 'vue';
import {store} from '../services';
import {maxDevice} from '@teemill/common/helpers';

const props = defineProps<{
  direction: 'column' | 'row';
  overrideMenuItems?: {text: string; href: string}[];
}>();

const menuItems = computed(() =>
  props.overrideMenuItems?.length
    ? props.overrideMenuItems
    : store.state.subdomain?.mainMenu
);
</script>

<template>
  <div
    class="flex flex-wrap gap-x-4 gap-y-3"
    :style="{
      flexDirection: direction,
    }"
  >
    <tml-anchor
      v-for="(item, index) in menuItems"
      :key="index"
      class="shrink-0"
      :class="{
        'font-bold': maxDevice('md'),
      }"
      :href="item.href"
      :text="item.text"
      :style="{
        color: 'var(--tml-menu-text-color) !important',
        fontFamily: 'var(--tml-menu-text-font) !important',
        textTransform: 'var(--tml-menu-text-transform) !important',
      }"
    />
  </div>
</template>
