<script setup lang="ts">
import {ref, computed} from 'vue';
import {faChevronLeft, faChevronDown} from '@fortawesome/pro-light-svg-icons';
import {store} from '../services';

defineProps<{
  direction: 'column' | 'row';
}>();

type MenuSegment = {
  title: string;
  url?: string;
  items: {
    title: string;
    url: string;
  }[];
};

type MenuItem = {
  title: string;
  segments?: MenuSegment[];
  url?: string;
};

const menuSegments: {title: string; segments: MenuSegment[]}[] = [
  {
    title: 'Women',
    segments: [
      {
        title: 'Featured',
        items: [
          {
            title: 'New Arrivals',
            url: '/collection/womens-new-in/',
          },
          {
            title: 'Bestsellers',
            url: '/collection/womens-bestsellers/',
          },
          {
            title: 'Plain Basics',
            url: '/collection/womens-plain-t-shirts-jumpers/',
          },
          {
            title: 'Graphic T-Shirts',
            url: '/collection/womens-organic-printed-t-shirts/',
          },
          {
            title: 'Bundles',
            url: '/collection/womens-bundles/',
          },
          {
            title: 'Recycled Collection',
            url: '/collection/womens-recycled-collection/',
          },
          {
            title: 'SALE',
            url: '/collection/womens-clearance/',
          },
        ],
      },
      {
        title: 'Clothing',
        url: '/collection/womens-collection/',
        items: [
          {
            title: 'View All',
            url: '/collection/womens-collection/',
          },
          {
            title: 'Hoodies',
            url: '/collection/womens-hoodies/',
          },
          {
            title: 'Jackets',
            url: '/collection/sustainable-coats-and-jackets-for-women/',
          },
          {
            title: 'Knitwear',
            url: '/collection/womens-knitwear/',
          },
          {
            title: 'Shirts',
            url: '/collection/womens-shirts/',
          },
          {
            title: 'Shorts',
            url: '/collection/womens-cotton-shorts/',
          },
          {
            title: 'Socks & Underwear',
            url: '/collection/womens-organic-cotton-underwear/',
          },
          {
            title: 'Sweatshirts',
            url: '/collection/womens-sweatshirts/',
          },
          {
            title: 'Swimwear',
            url: '/collection/womens-swimwear-bikinis/',
          },
          {
            title: 'Trousers & Jumpsuits',
            url: '/collection/trousers-jumpsuits/',
          },
          {
            title: 'T-shirts & Vests',
            url: '/collection/womens-t-shirts-vests/',
          },
        ],
      },
      {
        title: 'Accessories',
        url: '/collection/womens-accessories/',
        items: [
          {
            title: 'View All',
            url: '/collection/womens-accessories/',
          },
          {
            title: 'Art Prints',
            url: '/collection/sustainable-art-prints/',
          },
          {
            title: 'Backpacks & Bags',
            url: '/collection/womens-bags/',
          },
          {
            title: 'Gift Cards',
            url: '/collection/gift-voucher/',
          },
          {
            title: 'Headwear',
            url: '/collection/womens-headwear/',
          },
          {
            title: 'Robes & Towels',
            url: '/collection/womens-surf-towels-and-swim-robes/',
          },
          {
            title: 'Socks & Underwear',
            url: '/collection/womens-organic-cotton-underwear/',
          },
        ],
      },
      {
        title: 'Collaborations',
        url: '/collaborations/',
        items: [
          {
            title: 'View All',
            url: '/collaborations/',
          },
          {
            title: 'Ocean Photographer Of The Year',
            url: '/rapanui-x-opy/',
          },
          {
            title: 'BBC Earth',
            url: '/collection/rapanui-x-bbc-earth',
          },
          {
            title: 'Clean Seas Collection',
            url: '/collection/womens-clean-sea-collection/',
          },
          {
            title: 'Sea Shepherd',
            url: '/collection/rapanui-x-sea-shepherd/',
          },
          {
            title: 'Surfers Against Sewage',
            url: '/collection/rapanui-x-surfers-against-sewage/',
          },
        ],
      },
    ],
  },
  {
    title: 'Men',
    segments: [
      {
        title: 'Featured',
        items: [
          {
            title: 'New Arrivals',
            url: '/collection/mens-new-in/',
          },
          {
            title: 'Bestsellers',
            url: '/collection/mens-bestsellers/',
          },
          {
            title: 'Plain Basics',
            url: '/collection/mens-plain-t-shirts-jumpers/',
          },
          {
            title: 'Graphic T-Shirts',
            url: '/collection/mens-printed-organic-t-shirts/',
          },
          {
            title: 'Bundles',
            url: '/collection/mens-bundles/',
          },
          {
            title: 'Recycled Collection',
            url: '/collection/mens-recycled-collection/',
          },
          {
            title: 'SALE',
            url: '/collection/mens-clearance/',
          },
        ],
      },
      {
        title: 'Clothing',
        url: '/collection/mens-collection/',
        items: [
          {
            title: 'View All',
            url: '/collection/mens-collection/',
          },
          {
            title: 'Hoodies',
            url: '/collection/mens-hoodies/',
          },
          {
            title: 'Jackets',
            url: '/collection/mens-sustainable-jackets/',
          },
          {
            title: 'Knitwear',
            url: '/collection/mens-knitwear/',
          },
          {
            title: 'Shirts',
            url: '/collection/mens-flannel-shirts/',
          },
          {
            title: 'Shorts',
            url: '/collection/mens-cotton-shorts/',
          },
          {
            title: 'Socks & Underwear',
            url: '/collection/mens-organic-cotton-underwear/',
          },
          {
            title: 'Sweatshirts',
            url: '/collection/mens-sweatshirts/',
          },
          {
            title: 'Swimwear',
            url: '/collection/mens-swimwear-swim-shorts/',
          },
          {
            title: 'T-shirts & Vests',
            url: '/collection/mens-t-shirts-vests/',
          },
        ],
      },
      {
        title: 'Accessories',
        url: '/collection/mens-accessories/',
        items: [
          {
            title: 'View All',
            url: '/collection/mens-accessories/',
          },
          {
            title: 'Art Prints',
            url: '/collection/sustainable-art-prints/',
          },
          {
            title: 'Backpacks & Bags',
            url: '/collection/mens-backpacks-bags/',
          },
          {
            title: 'Gift Cards',
            url: '/collection/gift-voucher/',
          },
          {
            title: 'Headwear',
            url: '/collection/mens-headwear/',
          },
          {
            title: 'Robes & Towels',
            url: '/collection/mens-surf-towels-and-swim-robes/',
          },
          {
            title: 'Socks & Underwear',
            url: '/collection/mens-organic-cotton-underwear/',
          },
        ],
      },
      {
        title: 'Collaborations',
        url: '/collaborations/',
        items: [
          {
            title: 'View All',
            url: '/collaborations/',
          },
          {
            title: 'Ocean Photographer Of The Year',
            url: '/rapanui-x-opy/',
          },
          {
            title: 'BBC Earth',
            url: '/collection/rapanui-x-bbc-earth',
          },
          {
            title: 'Clean Seas Collection',
            url: '/collection/mens-clean-seas-collection/',
          },
          {
            title: 'Sea Shepherd',
            url: '/collection/rapanui-x-sea-shepherd/',
          },
          {
            title: 'Surfers Against Sewage',
            url: '/collection/rapanui-x-surfers-against-sewage/',
          },
        ],
      },
    ],
  },
];

const menuItems = computed(() => {
  const menuItems = store.state.subdomain?.mainMenu;

  if (!menuItems || !menuItems.length) {
    return [];
  }

  return menuItems.map((item: {text: string; href: string}) => {
    return {
      title: item.text,
      url: item.href,
      segments: menuSegments.find(segment => segment.title === item.text)
        ?.segments,
    };
  });
});

const activeMenuItem = ref<MenuItem>();
const activeMenuSegment = ref<MenuSegment>();

const exitTimeout = ref<ReturnType<typeof setTimeout>>();

function onMenuItemMouseLeave() {
  if (!activeMenuItem.value?.segments) {
    activeMenuItem.value = undefined;
    return;
  }

  exitTimeout.value = setTimeout(() => {
    activeMenuItem.value = undefined;
  }, 100);
}

function onMenuItemMouseEnter(item: MenuItem) {
  activeMenuItem.value = item;
  clearTimeout(exitTimeout.value);
}

function onNestedMenuMouseEnter() {
  clearTimeout(exitTimeout.value);
}

function onMobileMenuItemClick(item: MenuItem) {
  if (item.segments?.length) {
    activeMenuItem.value = item;
  }
}

function onMobileMenuSegmentClick(segment: MenuSegment) {
  activeMenuSegment.value = segment;
}
</script>

<template>
  <div
    class="flex gap-x-4 gap-y-3"
    :style="{
      flexDirection: direction,
    }"
  >
    <template v-if="direction === 'row'">
      <tml-anchor
        v-for="(item, index) in menuItems"
        :key="index"
        class="shrink-0 py-6 !text-[--tml-menu-text-color]"
        :text="item.title"
        :style="{
          fontFamily: 'var(--tml-menu-text-font) !important',
          textTransform: 'var(--tml-menu-text-transform) !important',
        }"
        :href="item.url || '#'"
        @mouseover="onMenuItemMouseEnter(item)"
        @mouseleave="onMenuItemMouseLeave"
      >
        <p>
          {{ item.title }}
          <font-awesome-icon
            v-if="item.segments?.length"
            class="ml-1 text-sm"
            :icon="faChevronDown"
          />
        </p>
      </tml-anchor>
    </template>
    <transition-group v-else name="slide-right">
      <template v-if="!activeMenuItem">
        <tml-anchor
          v-for="(item, index) in menuItems"
          :key="index"
          class="shrink-0 block !text-[--tml-menu-text-color]"
          :href="item.segments?.length ? '#' : item.url"
          :text="item.title"
          :style="{
            fontFamily: 'var(--tml-menu-text-font) !important',
          }"
          @click="onMobileMenuItemClick(item)"
        >
          <h4 class="mb-2">
            {{ item.title }}
            <font-awesome-icon
              v-if="item.segments?.length"
              class="mr-2 rotate-180 float-right pb-1.5"
              :icon="faChevronLeft"
            />
          </h4>
        </tml-anchor>
      </template>
      <template v-else-if="activeMenuItem && !activeMenuSegment">
        <tml-anchor
          class="text-sm font-bold mb-4 block"
          @click="activeMenuItem = undefined"
        >
          <p class="font-bold mb-2 !text-[--tml-form-text-secondary-color]">
            <font-awesome-icon class="mr-2" :icon="faChevronLeft" /> All
          </p>
        </tml-anchor>
        <tml-anchor :href="activeMenuItem.url || '#'">
          <h4 class="mb-4">
            {{ activeMenuItem.title }}
          </h4>
        </tml-anchor>
        <div
          v-for="(segment, segmentIndex) in activeMenuItem.segments"
          :key="segmentIndex"
        >
          <tml-anchor
            text="Link"
            href="#"
            @click="onMobileMenuSegmentClick(segment)"
          >
            <h5 class="font-bold mb-2">
              {{ segment.title }}
              <font-awesome-icon
                class="mr-2 rotate-180 float-right pb-1.5"
                :icon="faChevronLeft"
              />
            </h5>
          </tml-anchor>
        </div>
      </template>
      <template v-else-if="activeMenuSegment">
        <tml-anchor
          class="text-sm font-bold mb-4 block"
          @click="activeMenuSegment = undefined"
        >
          <p class="font-bold mb-2 !text-[--tml-form-text-secondary-color]">
            <font-awesome-icon class="mr-2" :icon="faChevronLeft" />
            {{ activeMenuItem.title }}
          </p>
        </tml-anchor>
        <tml-anchor :href="activeMenuSegment.url || '#'">
          <h4 class="mb-4">
            {{ activeMenuSegment.title }}
          </h4>
        </tml-anchor>
        <tml-anchor
          v-for="(item, itemIndex) in activeMenuSegment.items"
          :key="itemIndex"
          class="shrink-0 block !text-[--tml-menu-text-color]"
          :text="item.title"
          :href="item.url"
          :style="{
            fontFamily: 'var(--tml-menu-text-font) !important',
          }"
        >
          <p class="mb-2">
            {{ item.title }}
          </p>
        </tml-anchor>
      </template>
    </transition-group>
  </div>
  <template v-if="direction === 'row'">
    <transition name="open-down">
      <div
        v-if="activeMenuItem?.segments"
        class="nested-menu w-full absolute left-0 right-0 top-[100%] bg-[--tml-menu-background-color] z-20 p-8 border-t overflow-y-auto"
        @mouseover="onNestedMenuMouseEnter"
      >
        <div class="container">
          <tml-grid :breakpoints="{xs: activeMenuItem.segments.length}">
            <div
              v-for="(segment, segmentIndex) in activeMenuItem.segments"
              :key="segmentIndex"
            >
              <h4 class="font-bold mb-2">
                {{ segment.title }}
              </h4>
              <tml-anchor
                v-for="(item, itemIndex) in segment.items"
                :key="itemIndex"
                :href="item.url"
                @click="activeMenuItem = undefined"
              >
                <p class="mb-2 text-sm">
                  {{ item.title }}
                </p>
              </tml-anchor>
            </div>
          </tml-grid>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <div
        v-if="activeMenuItem?.segments"
        class="fixed left-0 right-0 top-[100%] bg-black/25 z-10 h-screen"
        @mouseover="activeMenuItem = undefined"
      />
    </transition>
  </template>
</template>

<style scoped lang="scss">
.nested-menu {
  max-height: calc(100vh - 100px);
}
.slide-right-enter-active {
  transition: all 0.4s ease;
}
.slide-right-leave-active {
}
.slide-right-enter-from {
  transform: translateX(20px);
  opacity: 0;
}
.slide-right-leave-to {
  height: 0;
}
</style>
