import Cookie from 'js-cookie';
import {snackbar, useGtm} from '@teemill/common/services';
import {tracker} from '@teemill/common/plugins';
import {NotificationHandler} from '../types/notificationHandler';
import {captureException} from '@sentry/vue';

export const subscriberOfferAccept: NotificationHandler = {
  path: 'plugins/subscriber-offer/subscribe',
  handler: async (notification, {dismiss}) => {
    useGtm().trackEvent({event: 'onSubscribeOfferAccept'});
    useGtm().trackEvent({event: 'onTeemailSubscribe'});
    tracker.event('subscriber-offer-accept');
    tracker.event('teemail-subscribe');

    const {MailingList, ValidationError} = await import(
      '@teemill/modules/teemail'
    );

    try {
      await MailingList.subscribe(
        notification.data.division,
        notification.data.mailingList,
        {
          email: notification.data.email,
          firstName: notification.data.firstName,
        }
      );

      snackbar("We've sent you an email to verify your subscription");

      dismiss();

      Cookie.set('subscriber-offer', 'subscribed', {
        expires: 365,
      });
    } catch (error: any) {
      if (error instanceof ValidationError) {
        snackbar.error(error.message);
      } else {
        captureException(error);
        snackbar.error('Oops... Something went wrong, please try again later');
      }
    }
  },
};

export const dismissSubscriberOffer: NotificationHandler = {
  path: 'plugins/subscriber-offer/dismiss',
  handler: async (notification, {dismiss}) => {
    useGtm().trackEvent({event: 'onSubscribeOfferDismiss'});
    tracker.event('subscriber-offer-dismiss');

    Cookie.set('subscriber-offer', 'dismissed', {
      // We'll expire the dismissed cookie after a month,
      // this gives users who have previously rejected the
      // chance to subscribe seecond time round if they
      // visit after a reasonable delay between visits
      expires: 30,
    });

    dismiss();
  },
};
