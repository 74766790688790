import {isFeatureEnabled, setup as setupFeatures} from './features';

import {createApp} from 'vue';
import {RouterHistory} from 'vue-router';
import {createHead} from '@vueuse/head';

import {validator, breadcrumbs} from '@teemill/common/plugins';

/**
 * Access Statements
 */
export * from './access';
import {AccessStatement} from '@teemill/common/classes';

/**
 * Components
 */
export {default as TmlStoreFront} from './StoreFront.vue';
export {default as StoreFrontMenu} from './components/StoreFrontMenu.vue';
export {default as StoreFrontHeaderIcons} from './components/StoreFrontHeaderIcons.vue';
export {default as StoreFrontMinimalFooter} from './components/StoreFrontMinimalFooter.vue';
export {default as StoreFrontSubfooter} from './components/StoreFrontSubfooter.vue';
export {default as StoreFrontFooter} from './components/StoreFrontFooter.vue';
export {default as ChristmasReassuranceBanner} from './components/ChristmasReassuranceBanner.vue';

import StoreFront from './StoreFront.vue';

export {createRouter} from './services/router';

import type {AppMode} from '@teemill/modules/app';
import {TeemillComponents} from '@teemill/components/plugin';
import PageFramework from '@teemill/modules/page-framework';
import {
  http,
  gtm,
  sentry,
  fontawesome,
  events,
  dialogs,
  snackbar,
  datadog,
  stripe,
} from '@teemill/common/services';

import {tracker} from '@teemill/common/plugins';
import {paymentProcessorStore} from '@teemill/common/vuex';

import {store, createRouter} from './services';
import {overlays} from '@teemill/modules/overlays';

import type {Theme} from '@teemill/modules/theme-builder';
import {default as TmlThemeBuilder} from '@teemill/modules/theme-builder';

export const createStoreFrontApp = (options: {
  mode: AppMode;
  history: RouterHistory;
  platformTheme?: () => Theme | undefined;
}) => {
  setupFeatures();

  const router = createRouter(options.history);

  if (options.mode === 'native') {
    let accessAttempts = 0;

    router.beforeEach((to, from, next) => {
      if (to.meta.access) {
        const result = AccessStatement.run(
          to.meta.access as AccessStatement[],
          to
        );

        if (!result.passed) {
          accessAttempts += 1;

          if (accessAttempts > 100) {
            accessAttempts = 0;

            // eslint-disable-next-line
            console.error('Possible access loop detected');

            next('/404/');
          } else if (result.statement) {
            next(result.statement.redirect(to));
          }

          return;
        }

        accessAttempts = 0;
      }

      next();
    });

    router.afterEach(async to => {
      performance.mark('After Each, router');
      tracker.event('page-view', {
        name: String(to.name),
        url: to.fullPath,
      });
    });
  }

  /**
   * Register Deprecated Globals
   */
  window.$axios = http;
  window.axios = http;
  window.$store = store;
  window.$router = router;
  window.snackbar = snackbar;
  window.$eventBus = events;
  window.dataLayer = window.dataLayer || [];

  window.gtag = function () {
    // eslint-disable-next-line
    window.dataLayer?.push(arguments);
  };

  AccessStatement.store = store;
  AccessStatement.router = router;

  const app = createApp(StoreFront)
    .use(app => {
      app.provide('app-mode', options.mode);
      app.provide('platformTheme', options.platformTheme || (() => undefined));
    })
    .use(http)
    .use(store)
    .use(router)
    .use(overlays)
    .use(breadcrumbs, {store, router})
    .use(tracker)
    .use({
      install(app) {
        app.config.globalProperties.$app = app;
        app.config.globalProperties.axios = http;
        app.config.globalProperties.$store = store;
        app.config.globalProperties.$eventBus = events;
        app.config.globalProperties.$newValidator = validator;
      },
    })
    .use(dialogs, store)
    .use(createHead())
    .use(TmlThemeBuilder)
    .use(paymentProcessorStore, store);

  if (isFeatureEnabled('datadog')) {
    app.use(datadog, {
      applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      version: import.meta.env.VITE_APP_VERSION,
      service: import.meta.env.VITE_APP_NAME,
      sampleRate: 0,
    });
  }

  if (isFeatureEnabled('fontawesome')) {
    app.use(fontawesome);
  }

  if (isFeatureEnabled('pages')) {
    app.use(PageFramework);
  }

  if (isFeatureEnabled('components')) {
    app.use(TeemillComponents);
  }

  if (isFeatureEnabled('sentry')) {
    app.use(sentry, {
      router,
      version: import.meta.env.VITE_APP_VERSION,
    });
  }

  if (isFeatureEnabled('gtm')) {
    window.gtag('consent', 'default', {
      ad_user_data: 'denied',
      ad_personalization: 'denied',
      ad_storage: 'denied',
      analytics_storage: 'denied',
      wait_for_update: 500,
    });

    window.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'});

    app.use(gtm, {
      id: import.meta.env.VITE_GTM_ID,
      debug: import.meta.env.VITE_GTM_DEBUG === 'true',
      router,
      trackViewEventProperty: 'onPageView',
      additionalEventData: () => ({
        ...store.getters['subdomain/getPluginTrackingInfo'],
        ...store.getters['subdomain/getGlobalTrackingInfo'],
        userId: store.state.subdomain.sessionId,
      }),
    });
  }

  if (isFeatureEnabled('stripe')) {
    app.use(stripe, {router});
  }

  return app;
};
