<script setup lang="ts">
import {camelCase, get} from 'lodash';
import {useRouter, isNavigationFailure} from 'vue-router';
import {provide, reactive, ref, computed} from 'vue';

import AppTheme from './AppTheme.vue';
import AppLayout from './AppLayout.vue';
import {App} from '../classes/app';
import {LayoutSections} from '../types/layoutOptions';
import {TmlStorage} from '@teemill/common/classes';

performance.mark('app-base-setup');

type Layouts = Record<string, LayoutSections>;

const props = defineProps<{
  app: App;
  layouts?: Layouts;
}>();

const standardLayout = computed<LayoutSections>(
  () =>
    props.layouts?.standard || {
      announcement: {show: true},
      header: {
        show: false,
        fixed: true,
        overlay: false,
        width: '100%',
        height: '4rem',
        content: true,
        logoMaxWidth: '220px',
        centerLogo: false,
        logo: true,
      },
      sidebar: {
        show: false,
        fixed: true,
        full: false,
        width: '220px',
      },
      content: {show: true, width: '100%'},
      footer: {show: false},
      subFooter: {show: false},
      navBar: {show: false, height: '3rem'},
    }
);

const layoutName = ref<keyof Layouts>('standard');
const layoutOptions: LayoutSections = reactive({
  ...standardLayout.value,
  announcement: false,
  header: false,
  sidebar: false,
  content: false,
  footer: false,
  subFooter: false,
  navBar: false,
});

provide('app', props.app);

useRouter().afterEach(async (to, from, failure) => {
  performance.mark('After Each, AppBase');

  if (isNavigationFailure(failure)) {
    return;
  }

  layoutName.value = camelCase(
    (to.meta.layout as string) || 'standard'
  ) as keyof Layouts;

  Object.assign(
    layoutOptions,
    get(props.layouts, layoutName.value, standardLayout)
  );

  document.title = (to.meta?.title as string) || document.title;
});

const storage = computed(() => {
  return TmlStorage.session;
});
</script>

<template>
  <app-theme v-if="app.theme" :theme="app.theme">
    <app-layout :layout="layoutOptions">
      <template #announcement="{options}">
        <slot name="announcement" v-bind="{type: layoutName, options}" />
      </template>

      <template
        v-if="storage.get('operator') !== 'headless'"
        #header="{options}"
      >
        <slot name="header" v-bind="{type: layoutName, options}" />
      </template>

      <template
        v-if="storage.get('operator') !== 'headless'"
        #sidebar="{options}"
      >
        <slot name="sidebar" v-bind="{type: layoutName, options}" />
      </template>

      <template #content="{options}">
        <slot name="before-content" v-bind="{type: layoutName, options}" />

        <slot name="content" v-bind="{type: layoutName, options}">
          <router-view />
        </slot>

        <slot name="after-content" v-bind="{type: layoutName, options}" />
      </template>

      <template #footer="{options}">
        <slot name="footer" v-bind="{type: layoutName, options}" />
      </template>

      <template #subfooter="{options}">
        <slot name="subfooter" v-bind="{type: layoutName, options}" />
      </template>

      <template #nav-bar="{options}">
        <slot name="nav-bar" v-bind="{type: layoutName, options}" />
      </template>

      <template #global="{options}">
        <slot name="global" v-bind="{type: layoutName, options}" />
      </template>
    </app-layout>
  </app-theme>
</template>
