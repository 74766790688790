import {App, Plugin} from 'vue';
import $script from 'scriptjs';

export const datadog: Plugin = {
  async install(
    app: App,
    {
      applicationId,
      clientToken,
      version,
      service,
      sampleRate = 25,
    }: {
      applicationId: string;
      clientToken: string;
      version: string;
      service: string;
      sampleRate: number;
    }
  ): Promise<void> {
    const initialiseDatadog = () => {
      if (window.DD_RUM === undefined) {
        return;
      }

      window.DD_RUM.onReady(() => {
        window.DD_RUM.init({
          applicationId,
          clientToken,
          service,
          version,
          useCrossSiteSessionCookie: true,
          useSecureSessionCookie: true,
          site: 'datadoghq.eu',
          env: import.meta.env.PROD ? 'production' : 'development',
          sampleRate: 0,
          premiumSampleRate: 0,
          trackResources: true,
          trackLongTasks: true,
          trackInteractions: true,
          trackFrustrations: true,
          defaultPrivacyLevel: 'mask-user-input',
          allowedTracingOrigins: [
            'https://teemill.com',
            window.location.origin,
          ],
          enableExperimentalFeatures: ['clickmap'],
        });

        window.DD_RUM.startSessionReplayRecording();
      });
    };

    $script(
      'https://www.datadoghq-browser-agent.com/datadog-rum-v4.js',
      initialiseDatadog
    );
  },
};

// if (
//   process.env.NODE_ENV === 'production' &&
//   !isbot(navigator.userAgent)
// ) {

// }
