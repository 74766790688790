<script setup lang="ts">
import {defineAsyncComponent, ref} from 'vue';
import {useResizeObserver, useWindowScroll, computedEager} from '@vueuse/core';

import {viewportSize} from '@teemill/common/helpers';
import {AppHeader} from '@teemill/modules/app';

import {store} from '../services';
import StoreFrontMenu from './StoreFrontMenu.vue';
import StoreFrontNestedMenu from './StoreFrontNestedMenu.vue';
import StoreFrontHeaderIcons from './StoreFrontHeaderIcons.vue';
const StoreFrontHeaderSearchBar = defineAsyncComponent(
  () => import('./StoreFrontHeaderSearchBar.vue')
);

defineProps<{
  noClickStyles: {pointerEvents: string | undefined};
}>();

const {y: scrollY} = useWindowScroll();

const logoHeight = computedEager(() => {
  if (viewportSize.isSmaller('md')) {
    return '2.5rem';
  }

  if (scrollY.value === 0) {
    return '4rem';
  }

  return '3rem';
});

const searchBarContainer = ref<HTMLElement | undefined>(undefined);
const shouldShowSearchBar = ref<boolean>(false);

const isRapanui = store.state.subdomain.division === 14;

if (isRapanui) {
  useResizeObserver(searchBarContainer, entries => {
    shouldShowSearchBar.value = entries[0].contentRect.width > 220;
  });
}
</script>

<template>
  <app-header :style="{...noClickStyles}">
    <template #logo>
      <tml-anchor href="/" class="flex items-center h-full w-full py-2">
        <tml-logo :height="`Min(${logoHeight}, 100%)`" header-align />
      </tml-anchor>
    </template>
    <template #content>
      <div v-if="isRapanui && viewportSize.isGreater('md')" class="ml-4">
        <store-front-nested-menu direction="row"/>
      </div>
      <store-front-menu
        v-else-if="viewportSize.isGreater('md')"
        class="ml-4"
        direction="row"
      />
      <div class="grow flex items-center justify-end">
        <div
          v-if="viewportSize.isGreater('md')"
          ref="searchBarContainer"
          class="ml-4 mr-2 mt-2 w-full"
          style="max-width: 300px"
        >
          <store-front-header-search-bar v-if="shouldShowSearchBar" />
        </div>

        <store-front-header-icons
          :excluded="shouldShowSearchBar ? ['search'] : []"
        />
      </div>
    </template>
  </app-header>
</template>
