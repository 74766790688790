import {App, Plugin, ref} from 'vue';
import {Router} from 'vue-router';
import {loadStripe as load} from '@stripe/stripe-js/pure';
import isbot from 'isbot';
import {usePaymentProcessorStore} from '../vuex/payment-processor';

const {getter} = usePaymentProcessorStore();

export const failedToLoadStripe = ref(false);

export const loadStripe = async (stripeAccountId?: string) => {
  try {
    return await load(import.meta.env.VITE_STRIPE_PK, {
      stripeAccount: stripeAccountId,
    });
  } catch (error) {
    failedToLoadStripe.value = true;

    if (!isbot(navigator.userAgent)) {
      throw error;
    }

    return;
  }
};

export const stripe: Plugin = {
  install(app: App, {router}: {router: Router}): void {
    router.afterEach(async (to, from) => {
      if (from.name !== undefined && !window.Stripe) {
        loadStripe(getter('stripeAccountId'));
      }
    });
  },
};
